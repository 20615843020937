import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import SubpageHero from "../components/subPageHero"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SubpageWrapper from "../components/subpageWrapper"

const ServicesPage = () => (
  <Layout>
    <Seo title="Services" />
    <SubpageHero h1="Services" />
    <SubpageWrapper>
      <p>When tragedy strikes it is important to keep calm.  Contacting a team of professionals that specialize in this field is pertinent to ensure that proper procedures are followed.  Our training and experience put Priority One at the forefront of prompt and professional service.</p>

      <p>Below is a non-exhaustive list of the services we provide.</p>

      <h2>Emergency Services</h2>
      
      <h3>Emergency Water Response</h3>
      <ul>
        <li>Water extraction</li>
        <li>Selective demolition </li>
        <li>Moisture mapping and dryout</li>
      </ul>

      <h3>Debris Removal</h3>
      <ul>
        <li>Clean up and off haul of debris from resulting property 
            Damage.</li>
      </ul>

      <h3>Board Up Services</h3>
      <ul>
        <li>In the event of property damage such as a tree strike or fire, securing damaged windows and doors keeps out the elements and unwanted guests.</li>
      </ul>

      <h3>Tarp Off</h3>
      <ul>
        <li>Covering openings in your structure helps to control the environment and prevents further damage.</li>
      </ul>

      <h3>Temporary Fencing</h3>
      <ul>
        <li>Priority One secures your property from unauthorized access</li>
      </ul>

      <h3>Tarp off</h3>
      <ul>
        <li>Covering openings in your structure helps to control the environment and prevents further damage.</li>
      </ul>

      <h3>Contents Service</h3>
      <ul>
        <li>Smoke and water damage control</li>
        <li>Cleaning of hard surfaces</li>
        <li>Textile cleaning</li>
        <li>Odor control</li>
        <li>Water extraction and drying</li>
      </ul>

      <h3>Utility inspection</h3>
      <ul>
        <li>Investigation of utilities for safety and restoration of services as necessary.</li>
      </ul>

      <p>A quick response to protect your property and belongings is crucial when mitigating damage.  Minutes count.  Following IICRC standards ensures proper protocols when dealing with your loss.  For more information about any of our services contact our team of skilled professionals.</p>
    </SubpageWrapper>
  </Layout>
)

export default ServicesPage
